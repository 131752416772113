import classNames from "classnames"

import { useTranslation } from "@bounce/i18n"

import type { StoreContact as StoreContactObj } from "../../../hooks/useStoreContact"

export type StoreContactProps = {
  storeContact?: StoreContactObj
  className?: string
}

export const StoreContact = ({ storeContact, className }: StoreContactProps) => {
  const { t } = useTranslation()
  if (!storeContact) return null
  const { name, fullAddress, telephone } = storeContact

  return (
    <div className={classNames("mx-auto flex w-full max-w-screen-xl flex-col px-6 text-center", className)}>
      <h2 className="footer__title text-white">{name}</h2>
      <div className="footer__contact mt-1">
        <strong className="font-normal">{t("cmp.footer.address", "Address")}:</strong>{" "}
        <address className="inline not-italic">{fullAddress}</address>
      </div>
      <p className="footer__contact mt-1">
        <strong className="font-normal">{t("cmp.footer.phone", "Phone")}:</strong>{" "}
        <a href={`tel:${telephone}`} className="text-white">
          {telephone}
        </a>
      </p>
    </div>
  )
}
