import i18next from "i18next"

import { intlNumberFormat } from "./intlNumberFormat"
import { DEFAULT_CURRENCY, ZERO_DECIMAL_CURRENCIES } from "../constants/currencies"

/**
 * @example
 * getCurrencySymbol("USD") // $
 */
export const getCurrencySymbol = (currency = DEFAULT_CURRENCY, locale = i18next.language): string => {
  return intlNumberFormat({
    locale,
    options: {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  })
    .format(0)
    .replace(/\d/g, "")
    .trim()
}

export const formatCurrency = (amount = 0, currency = DEFAULT_CURRENCY, locale = i18next.language): string => {
  const _amount = Number(amount.toFixed(2))
  return intlNumberFormat({
    locale,
    options: { style: "currency", currency },
  }).format(_amount)
}

/**
 * Currency formatter for whole integers. Guarantee that the amount is a whole number since this will round to the nearest integer.
 */
export const formatCurrencyWhole = (amount = 0, currency = DEFAULT_CURRENCY, locale = i18next.language): string => {
  const _amount = Number(amount)
  return intlNumberFormat({
    locale,
    options: {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  }).format(_amount)
}

/**
 * @example stripeAmount(10.99, "USD") // 1099
 */
export const stripeAmount = (amount: number) => amount * 100

/**
 * @example revertStripeAmount(1099, "USD") // 10.99
 */
export const revertStripeAmount = (amount: number) => amount / 100

export const sanitizedStripeAmount = (amount: number, currency: string) => {
  if (ZERO_DECIMAL_CURRENCIES.includes(currency.toUpperCase())) {
    return amount / 100
  }

  return amount
}

/**
 * @example formatStripeAmount(1099, "USD") // 10.99$
 */
export const formatStripeAmount = (amount: number, currency: string, locale?: string) =>
  formatCurrency(revertStripeAmount(amount), currency, locale)

/**
 * @example formatWholeStripeAmount(1000, "USD") // 10$
 */
export const formatWholeStripeAmount = (amount: number, currency: string, locale?: string) =>
  formatCurrencyWhole(revertStripeAmount(amount), currency, locale)

/*
 * Apply an exchange rate to an amount
 * @example
 * const eurAmount = 450
 * const usdExchangeRate = 1.2135922330097089
 * const usdAmount = applyExchangeRate(eurAmount, usdExchangeRate) // 546
 */
export const applyExchangeRate = (amount: number, exchangeRate: number) => Math.round(amount * exchangeRate)
