import { isNumber } from "./number"

export type Coordinates = {
  latitude: number
  longitude: number
}

export type GeoIpData = {
  id: string | null
  ip: string | null
  country: string | null
  region: string | null
  city: string | null
  coordinates: Coordinates | null
  timezone: string | null
  address: string | null
}

export const getIPLocation = async (): Promise<GeoIpData> => {
  const res = await fetch("https://usebounce.com/api/geoip")
  const data = await res.json()

  return data
}

/**
 * Checks if the given object is a valid `Coordinates` object.
 *
 * @export
 * @param {Partial<Coordinates>?} coordinates - The object to check.
 * @returns {coordinates is Coordinates} Returns `true` if the object has valid latitude and longitude properties, otherwise `false`.
 *
 * @example
 * const partialCoord = { latitude: 45.1234 };
 * const fullCoord = { latitude: 45.1234, longitude: 12.3456 };
 *
 * console.log(isCoordinates(partialCoord)); // Expected output: false
 * console.log(isCoordinates(fullCoord));    // Expected output: true
 */
export const isCoordinates = (coordinates?: Partial<Coordinates>): coordinates is Coordinates => {
  return isNumber(coordinates?.latitude) && isNumber(coordinates?.longitude)
}

export const parseCoordinates = (coordinates?: Partial<Coordinates>): Coordinates | null => {
  return isCoordinates(coordinates) ? coordinates : null
}

/**
 * Format distance in meters to walking time
 * @example getWalkingTimeInMinutes(400) // 2 min
 */
export const getWalkingTimeInMinutes = (meters: number) => {
  //  Average walking speed: 3.9km/h
  const VELOCITY = 65

  return meters / VELOCITY
}

/**
 * Check if coordinates are in rectangle
 * @example
 * xy1┌───┐
 *    │xy │
 *    └───┘xy2
 * return true
 *
 * xy1┌───┐
 *    │   │
 *    └───┘xy2
 *      xy
 * return false
 */
export const pointInRect = (
  {
    x1,
    y1,
    x2,
    y2,
  }: {
    x1: number
    y1: number
    x2: number
    y2: number
  },
  {
    x,
    y,
  }: {
    x: number
    y: number
  },
) => [x1, x, x2].sort()[1] === x && [y1, y, y2].sort()[1] === y

/**
 * Converts numeric degrees to radians
 */
const toRad = (value: number) => (value * Math.PI) / 180

/**
 * Get distance in meters between two coordinates
 * @example coordinatesToDistanceInMeters(coord1, coord2) // 300
 */
export const coordinatesToDistanceInMeters = (coord1: Coordinates, coord2: Coordinates): number => {
  const R = 6371 // 1km
  const dLat = toRad(coord2.latitude - coord1.latitude)
  const dLon = toRad(coord2.longitude - coord1.longitude)
  const lat1 = toRad(coord1.latitude)
  const lat2 = toRad(coord2.latitude)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c

  return Math.round(d * 1000)
}
