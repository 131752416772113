import { Analytics } from "@bounce/analytics"
import { DEFAULT_LANGUAGE, useTranslation } from "@bounce/i18n"
import { useCookiePreferences } from "@bounce/legal"
import type { FooterLinkListProps } from "@bounce/web-components"

import { InteractionEvents } from "../../../libs/analytics/events"
import { useUrl } from "../../../libs/router"

export const useLinks = (): Required<FooterLinkListProps>["links"] => {
  const { getUrl } = useUrl()
  const { t, i18n } = useTranslation()

  const openCookiePreferences = useCookiePreferences.useOpenPreferences()
  const onClickSocialLink = (name: string) => {
    Analytics.track(InteractionEvents.SocialLinkPressed, { name })
  }

  const legalLinks = [
    {
      children: t("hooks.layouts.useFooterLinks.termsOfService", "Terms of Service"),
      href: getUrl({ path: "/legal/terms" }),
    },
    {
      children: t("hooks.layouts.useFooterLinks.privacyPolicy", "Privacy Policy"),
      href: getUrl({ path: "/legal/privacy" }),
    },
    {
      children: t("hooks.layouts.useFooterLinks.manageCookies", "Manage cookie preferences"),
      href: getUrl({ path: "#cookie-pref" }),
      onClick: openCookiePreferences,
    },
  ]

  if (i18n.language === "de") {
    legalLinks.unshift({
      children: "Impressum",
      href: "/legal/impressum",
    })
  }

  return [
    {
      title: {
        children: t("hooks.layouts.useFooterLinks.title.company", "Company"),
      },
      links: [
        {
          children: t("hooks.layouts.useFooterLinks.reviews", "Reviews"),
          href: "/reviews",
        },
        {
          children: t("hooks.layouts.useFooterLinks.getTheApp", "Get the app"),
          href: "/ls/app",
        },
        {
          children: t("hooks.layouts.useFooterLinks.guides", "Guides"),
          href: "/guides",
          locale: "en",
        },
        {
          children: t("hooks.layouts.useFooterLinks.events", "Events"),
          href: "/events",
          locale: "en",
        },
        {
          children: t("hooks.layouts.useFooterLinks.blog", "Blog"),
          href: "/blog",
          locale: "en",
        },
        {
          children: t("hooks.layouts.useFooterLinks.becomePartner", "Become a Partner"),
          href: "/partner",
        },
        {
          children: t("hooks.layouts.useFooterLinks.bounceHotels", "Bounce for Hotels"),
          href: "/partner/hotels",
        },
        {
          children: t("hooks.layouts.useFooterLinks.careers", "Careers"),
          href: "/careers",
          locale: "en",
        },
      ],
    },
    {
      title: {
        children: t("hooks.layouts.useFooterLinks.title.legal", "Legal"),
      },
      links: legalLinks,
    },
    {
      title: {
        children: t("hooks.layouts.useFooterLinks.title.resources", "Resources"),
      },
      links: [
        {
          children: t("hooks.layouts.useFooterLinks.faq", "FAQ"),
          href: "/#faq",
        },
        {
          children: t("hooks.layouts.useFooterLinks.support", "Support"),
          href: "/support",
        },
        {
          children: t("hooks.layouts.useFooterLinks.sitemap", "Sitemap"),
          href: "/sitemaps",
          locale: DEFAULT_LANGUAGE,
        },
      ],
    },
    {
      title: {
        children: t("hooks.layouts.useFooterLinks.title.follow", "Follow"),
      },
      links: [
        {
          children: t("hooks.layouts.useFooterLinks.facebook", "Facebook"),
          href: "https://www.facebook.com/BounceYourStuff/",
          rel: "nofollow noopener",
          onClick: () => onClickSocialLink("facebook"),
          target: "_blank",
        },
        {
          children: t("hooks.layouts.useFooterLinks.twitter", "Twitter"),
          href: "https://twitter.com/bouncemystuff",
          rel: "nofollow noopener",
          onClick: () => onClickSocialLink("twitter"),
          target: "_blank",
        },
        {
          children: t("hooks.layouts.useFooterLinks.linkedin", "Linkedin"),
          href: "https://www.linkedin.com/company/bounceyourstuff/",
          rel: "nofollow noopener",
          onClick: () => onClickSocialLink("linkedin"),
          target: "_blank",
        },
        {
          children: t("hooks.layouts.useFooterLinks.instagram", "Instagram"),
          href: "https://www.instagram.com/bouncemystuff",
          rel: "nofollow noopener",
          onClick: () => onClickSocialLink("instagram"),
          target: "_blank",
        },
        {
          children: t("hooks.layouts.useFooterLinks.tikTok", "TikTok"),
          href: "https://www.tiktok.com/@bouncemystuff",
          rel: "nofollow noopener",
          onClick: () => onClickSocialLink("tikTok"),
          target: "_blank",
        },
      ],
    },
  ]
}
