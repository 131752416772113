import { useRouter } from "next/router"

import type { Locale } from "@bounce/i18n"
import { useTranslation } from "@bounce/i18n"

import type { CityData } from "./bounce/cities"
import { config } from "../config"
import { HOMEPAGE_ONLY_LANGUAGES, IN_LANGUAGE_URL_LANGUAGES } from "../utils/i18n/locale"
import { getPathTranslation } from "../utils/i18n/pathTranslations"

type GetCityUrlPathArgs = {
  language: Locale["language"]
  city: Pick<CityData, "slug" | "translations">
}

type GetPoiUrlPathArgs = {
  language: Locale["language"]
  city: Pick<CityData, "slug" | "translations">
  poi: Pick<CityData, "slug" | "translations">
}

export const getCityUrlDefaultPath = (slug: CityData["slug"]) => `/city/${slug}`

export const getCityUrlPath = ({ language, city: { slug, translations } }: GetCityUrlPathArgs) => {
  const defaultPath = getCityUrlDefaultPath(slug)
  if (!IN_LANGUAGE_URL_LANGUAGES.includes(language)) {
    return defaultPath
  }

  const translatedSlug = translations.slug?.find(translation => translation?.language === language)
  if (!translatedSlug) {
    return defaultPath
  }

  return `/${getPathTranslation("luggage-storage", language)}/${translatedSlug.text}`
}

export const getPoiUrlPath = ({
  language,
  city: { translations: cityTranslations, slug: citySlug },
  poi: { translations: poiTranslations, slug: poiSlug },
}: GetPoiUrlPathArgs) => {
  const defaultPath = `/city/${citySlug}/${poiSlug}`
  if (!IN_LANGUAGE_URL_LANGUAGES.includes(language)) {
    return defaultPath
  }

  const translatedPoiSlug = poiTranslations.slug?.find(poiTranslation => poiTranslation?.language === language)
  if (!translatedPoiSlug) {
    return defaultPath
  }

  const translatedCitySlug = cityTranslations.slug?.find(cityTranslation => cityTranslation?.language === language)
  if (!translatedCitySlug) {
    return defaultPath
  }

  return `/${getPathTranslation("luggage-storage", language)}/${translatedCitySlug.text}/${translatedPoiSlug.text}`
}

export const getEventsCityUrlDefaultPath = ({ slug }: Pick<CityData, "slug">) => `/events/${slug}`

export const getEventsUrlDefaultPath = () => "/events"

export const useCityUrl = ({ city }: Pick<GetCityUrlPathArgs, "city">) => {
  const { i18n } = useTranslation()
  const { getUrl: getBaseUrl } = useUrl()

  const getUrl = ({ absolute = true, language = i18n.language } = {}) =>
    getBaseUrl({
      path: getCityUrlPath({ language, city }),
      absolute,
      locale: language,
    })

  return {
    getUrl,
    absolutePath: getUrl({ absolute: true }),
  }
}

export const usePoiUrl = ({ city, poi }: Pick<GetPoiUrlPathArgs, "city" | "poi">) => {
  const { i18n } = useTranslation()
  const { getUrl: getBaseUrl } = useUrl()

  const getUrl = ({ absolute = true, language = i18n.language } = {}) =>
    getBaseUrl({
      path: getPoiUrlPath({ language, city, poi }),
      absolute,
      locale: language,
    })

  return {
    getUrl,
    absolutePath: getUrl({ absolute: true }),
  }
}

export const useUrl = () => {
  const router = useRouter()

  const getUrl = ({ path = router.asPath, absolute = false, locale = router.locale, forceLocale = false } = {}) => {
    // remove url params and trailing slash
    let canonicalPath = path.split("?")[0]
    canonicalPath = canonicalPath !== "/" ? canonicalPath : ""

    const omitLocale = forceLocale
      ? false
      : locale === router.defaultLocale || HOMEPAGE_ONLY_LANGUAGES.includes(locale ?? "")
    const prefix = omitLocale ? "" : `/${locale}`
    const domain = absolute ? `https://${config.domain}` : ""
    const url = `${domain}${prefix}${canonicalPath}`

    return url === "" ? "/" : url
  }

  return {
    getUrl,
    absolutePath: getUrl({ absolute: true }),
  }
}
